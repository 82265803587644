<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              WhatYouWant
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            สมัครเป็นตัวแทนออนไลน์
          </p>
          <!--          <p class="mb-2">-->
          <!--            Make your app management easy and fun!-->
          <!--          </p>-->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="editedItem.email"
              outlined
              label="อีเมล"
              placeholder="อีเมล"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.email.$silentErrors"
              v-if="v$.editedItem.email.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.username"
              outlined
              label="ชื่อผู้ใช้งาน *"
              placeholder="ชื่อผู้ใช้งาน *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.username.$silentErrors"
              v-if="v$.editedItem.username.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="รหัสผ่าน"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-3"
            ></v-text-field>

            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.password.$silentErrors"
              v-if="v$.editedItem.password.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.password_confirmation"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="ยืนยันรหัสผ่าน"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.password_confirmation.$silentErrors"
              v-if="v$.editedItem.password_confirmation.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.name"
              outlined
              label="ชื่อ-นามสกุล *"
              placeholder="ชื่อ-นามสกุล *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.name.$silentErrors"
              v-if="v$.editedItem.name.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>


            <v-text-field
              v-model="editedItem.member_identification"
              outlined
              label="เลขที่บัตรประชาชน *"
              placeholder="เลขที่บัตรประชาชน *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.member_identification.$silentErrors"
              v-if="v$.editedItem.member_identification.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.phone"
              outlined
              label="เบอร์โทรศัพท์ *"
              placeholder="เบอร์โทรศัพท์ *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.phone.$silentErrors"
              v-if="v$.editedItem.phone.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.line_id"
              outlined
              label="Line Id *"
              placeholder="Line Id *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.line_id.$silentErrors"
              v-if="v$.editedItem.line_id.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>
            <v-text-field
              v-model="editedItem.shop_name"
              outlined
              label="ชื่อร้าน *"
              placeholder="ชื่อร้าน *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.shop_name.$silentErrors"
              v-if="v$.editedItem.shop_name.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.member_shop_address"
              outlined
              label="ที่อยู่ *"
              placeholder="ที่อยู่ *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.member_shop_address.$silentErrors"
              v-if="v$.editedItem.member_shop_address.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.facebook"
              outlined
              label="Facebook URL **"
              placeholder="ที่อยู่ *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.facebook.$silentErrors"
              v-if="v$.editedItem.facebook.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <v-text-field
              v-model="editedItem.instagram"
              outlined
              label="Instagram **"
              placeholder="Instagram *"
              hide-details
              class="mb-3"
            ></v-text-field>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.instagram.$silentErrors"
              v-if="v$.editedItem.instagram.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>


            <!--            <v-checkbox-->
            <!--              hide-details-->
            <!--              class="mt-1"-->
            <!--            >-->
            <!--              <template #label>-->
            <!--                <div class="d-flex align-center flex-wrap">-->
            <!--                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>-->
            <!--                </div>-->
            <!--              </template>-->
            <!--            </v-checkbox>-->

            <v-btn
              block
              color="primary"
              @click="save"
              class="mt-6"
            >
              สมัครเป็นตัวแทนจำหน่าย
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <!--          <span class="me-2">-->
          <!--            Already have an account?-->
          <!--          </span>-->
          <router-link :to="{ name:'pages-login' }">
            กลับไปยังหน้าการเข้าสู่ระบบ
          </router-link>
        </v-card-text>

        <!-- divider -->
        <!--        <v-card-text class="d-flex align-center mt-2">-->
        <!--          <v-divider></v-divider>-->
        <!--          <span class="mx-5">or</span>-->
        <!--          <v-divider></v-divider>-->
        <!--        </v-card-text>-->

        <!-- social link -->
        <!--        <v-card-actions class="d-flex justify-center">-->
        <!--          <v-btn-->
        <!--            v-for="link in socialLink"-->
        <!--            :key="link.icon"-->
        <!--            icon-->
        <!--            class="ms-1"-->
        <!--          >-->
        <!--            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">-->
        <!--              {{ link.icon }}-->
        <!--            </v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!--    <img-->
    <!--      class="auth-mask-bg"-->
    <!--      height="190"-->
    <!--      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"-->
    <!--    >-->

    <!--    &lt;!&ndash; tree &ndash;&gt;-->
    <!--    <v-img-->
    <!--      class="auth-tree"-->
    <!--      width="247"-->
    <!--      height="185"-->
    <!--      src="@/assets/images/misc/tree.png"-->
    <!--    ></v-img>-->

    <!--    &lt;!&ndash; tree  &ndash;&gt;-->
    <!--    <v-img-->
    <!--      class="auth-tree-3"-->
    <!--      width="377"-->
    <!--      height="289"-->
    <!--      src="@/assets/images/misc/tree-3.png"-->
    <!--    ></v-img>-->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import instance from "@/axios.service";
import instance_user from "@/services/user";
import Swal from "sweetalert2";
import {useVuelidate} from "@vuelidate/core";
import {minLength, required, sameAs} from "@vuelidate/validators";

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      v$: useVuelidate(),
      isPasswordVisible,
      username,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  validations() {

    let valid = {
      editedItem: {
        email: {required},
        username: { required },
        name: { required },
        member_identification: { required },
        password: { required, minLength: minLength(8) },
        password_confirmation: { sameAsPassword: sameAs(this.editedItem.password)},
        phone: { required },
        line_id: {required},
        shop_name: {required},
        member_shop_address: {required},
        facebook: {required},
        instagram: {required},
      }
    }
    return valid
  },
  data() {
    return {
      editedItem: {
        email: "",
        username: "",
        name: "",
        member_identification: "",
        password: "",
        password_confirmation: "",
        phone: "",
        line_id: "",
        shop_name: "",
        member_shop_address: "",
        facebook: "",
        instagram: "",
      }
    }
  },
  methods: {
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true;
      instance_user.register(this.editedItem).then(res => {
        this.$store.state.isLoading = false
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          })
          this.$router.push({'name' : "pages-login"})
        } else {
          this.$store.state.isLoading = false
          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          })
        }
      }).catch(err => {

          this.$store.state.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      )

    }

  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
